<template>
  <v-dialog
    v-model="show"
    max-width="20vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              cols="24"
              md="12"
            >
              <v-select
                v-model="cityId"
                :items="cities"
                :menu-props="{ maxHeight: '400' }"
                label="Город"
                dense
                :rules="[rules.required]"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'BranchForm',
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('branch/form', [
      'branchId',
      'cityId'
    ]),
    ...mapGetters('dictionary', { cities: 'cityDropDown' }),
  },
  async mounted() {
    await this.fetchCities()
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('branch/form', ['resetForm']),
    ...mapActions('dictionary', { fetchCities: 'fetchCities' }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
