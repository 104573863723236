<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="branches"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.restaurant`]="{item}">
        {{ item.restaurant }}
      </template>

      <template #[`item.city`]="{item}">
        {{ item.city }}
      </template>

      <template #[`item.address`]="{item}">
        {{ item.address }}
      </template>

      <template #[`item.phoneNumber`]="{item}">
        {{ item.phoneNumber }}
      </template>

      <template #[`item.isVisible`]="{item}">
        <v-icon
          size="20"
          class="rounded-0"
          @click="updateVisibility({branchId: item.id, isVisible: !item.isVisible})"
        >
          {{ item.isVisible ? icons.mdiEye : icons.mdiEyeOff }}
        </v-icon>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <!-- edit -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="updateDialogShow(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <branch-form :label="'Редактирование филиала'" :cancellation="updateDialogCancel" :show="updateDialog"
                 :save="updateBranch"></branch-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import { mdiPencilOutline,mdiEye, mdiEyeOff } from '@mdi/js'
import BranchForm from './branch-form'

export default {
  components: { BranchForm },
  name: 'BranchList',
  data: () => ({
    updateDialog: false,
    icons: {
      mdiPencilOutline,
      mdiEye,
      mdiEyeOff
    },
  }),
  computed: {
    ...mapFormHandlers('branch', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.restaurantId',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('branch', {
      branches: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Имя', value: 'name' },
        { text: 'Ресторан', value: 'restaurant' },
        { text: 'Город', value: 'city' },
        { text: 'Адрес', value: 'address' },
        { text: 'Телефон', value: 'phoneNumber' },
        { text: 'Доступен', value: 'isVisible' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    const { restaurantId } = this.$route.params
    this.filters.restaurantId = restaurantId
    this.fetchStreets()
  },
  methods: {
    ...mapActions('branch', {
      fetchStreets: 'fetchList',
      updateVisibility: 'updateVisibility',
    }),
    ...mapActions('branch/form', ['resetForm', 'fetchById', 'update']),
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
    async updateBranch() {
      await this.update()
      this.updateDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
